import React, { useState } from 'react';
import Layout from "../components/Layout";
import "../styles/HomeStyles.css";
import Banner from "../images/CMS.png";
import { Box, Typography, TextField, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faLock, faChartBar, faExpand } from '@fortawesome/free-solid-svg-icons';
import CMSPic from '../images/CMSPic.png';
import axios from "axios";

const CMS = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    emailId: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    phone: false,
    emailId: false,
  });

  const [backendError, setBackendError] = useState(''); // State for backend errors
  const [successMessage, setSuccessMessage] = useState(''); // State for success message

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false }); // Reset the error for the current field
  };

  const handleSubmit = async () => {
    const newErrors = {
      name: formData.name === '',
      phone: formData.phone === '',
      emailId: formData.emailId === '',
    };
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);
    if (!hasErrors) {
      try {
        const response = await axios.post("https://web-backend-to6f.onrender.com/ch-adda/cms/user", formData);
        console.log("Response:", response.data);
        setSuccessMessage('Request has been raised successfully. Team will connect very soon.'); // Set success message
        setFormData({ name: '', phone: '', emailId: '' }); // Clear the form fields
        setBackendError(''); // Clear backend error if any
      } catch (error) {
        if (error.response && error.response.data) {
          const serverError = error.response.data.message || 'An error occurred';
          setBackendError(serverError);
          setSuccessMessage(''); // Clear success message if there's an error
        } else {
          setBackendError('There was an issue submitting the form. Please try again later.');
          setSuccessMessage(''); // Clear success message if there's an error
        }
      }
    }
  };

  return (
    <Layout>
      <div className="home" style={{
        backgroundImage: `url(${Banner})`,
      }}>
      </div>
      <Box bgcolor="white" color="gray.800" py={8} px={4}>
        <Box textAlign="center" mb={4}>
          <Typography variant="h4" fontWeight="bold" mb={2}>
            What is Charging Management Software?
          </Typography>
          <Typography variant="h5" mb={1}>
            At Charging Adda, we provide a comprehensive software solution</Typography>
          <Typography variant="h5" mb={1}>
            for managing charging stations. Whether you have one or many,
          </Typography>
          <Typography variant="h5" mb={1}>
            our user-friendly platform lets you monitor, control, and optimize
          </Typography>
          <Typography variant="h5" mb={1}>
            your stations. With real-time data and analytics, you can track
          </Typography>
          <Typography variant="h5" mb={5}>
            usage, manage payments, and preempt issues.
          </Typography>
        </Box>

        <Box textAlign="center" mb={8}>
          <Typography variant="h4" fontWeight="bold">
            CMS Features
          </Typography>
        </Box>

        <Box display="grid" gridTemplateColumns={{ xs: '1fr', md: 'repeat(4, 1fr)' }} gap={8} textAlign="center">
          <Box>
            <Box display="flex" justifyContent="center" mb={4}>
              <FontAwesomeIcon icon={faClock} size="4x" color="green" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Real Time Monitoring
            </Typography>
            <Typography variant="body2">
              Keep track of charging station status and performance in real time.
            </Typography>
          </Box>

          <Box>
            <Box display="flex" justifyContent="center" mb={4}>
              <FontAwesomeIcon icon={faLock} size="4x" color="green" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Data Encryption
            </Typography>
            <Typography variant="body2">
              Protect sensitive data with advanced encryption methods.
            </Typography>
          </Box>

          <Box>
            <Box display="flex" justifyContent="center" mb={4}>
              <FontAwesomeIcon icon={faChartBar} size="4x" color="green" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Data Analytics
            </Typography>
            <Typography variant="body2">
              Track usage patterns and station performance with comprehensive data and analytics.
            </Typography>
          </Box>

          <Box>
            <Box display="flex" justifyContent="center" mb={4}>
              <FontAwesomeIcon icon={faExpand} size="4x" color="green" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Scalability
            </Typography>
            <Typography variant="body2">
              Efficiently grow and expand your network of charging stations with scalable solutions.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" py={5} px={2}
        bgcolor="#22D23F36" borderRadius={3} boxShadow={4} width="90%" mx="auto" mb={3}>
        <Typography variant="h4" fontWeight="650" mb={3} mt={3}>
          A single app to oversee all your EV chargers
        </Typography>

        <Box display="flex" justifyContent="center" gap={1} width="100%">
          <Box component="img" src={CMSPic} alt="Support Chat screen" width="90%" />
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" alignItems="center" height="auto" bgcolor="#ffffff" py={2} px={3}>
        <Box textAlign="left" mb={{ xs: 4, md: 0 }} mr={{ md: 3 }}>
          <Typography variant="h4" fontWeight="700" mb={2} ml={2}>
            Launch your charging business with ChargingAdda today!
          </Typography>
        </Box>

        <Box component="form" width="100%" maxWidth="400px" display="flex" flexDirection="column" ml={2}>
          <TextField
            variant="outlined"
            label="Name*"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            error={errors.name}
            helperText={errors.name ? 'Name is required' : ''}
          />
          <TextField
            variant="outlined"
            label="Phone number*"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            error={errors.phone}
            helperText={errors.phone ? 'Phone number is required' : ''}
          />
          <TextField
            variant="outlined"
            label="E-mail*"
            name="emailId"
            type="email"
            value={formData.emailId}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
            error={errors.emailId}
            helperText={errors.emailId ? 'Email is required' : ''}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            fullWidth
            sx={{ mt: 2, mb: 3 }}
          >
            Enquiry
          </Button>

          {successMessage && (
            <Typography color="success.main" variant="body1" mt={2}>
              {successMessage}
            </Typography>
          )}
          {backendError && (
            <Typography color="error" variant="body1" mt={2}>
              {backendError}
            </Typography>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export default CMS;
