import React from 'react';
import Layout from "./../components/Layout";
import { Box, Typography, Button } from "@mui/material";
import { Link } from 'react-router-dom';
import PageNotFoundImage from "../images/404.png";  
import "../styles/HomeStyles.css";  

const PageNotFound = () => {
  return (
    <Layout>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
        textAlign="center"
        p={4}
        bgcolor="#f5f5f5"
      >
        <Box
          component="img"
          src={PageNotFoundImage}  
          alt="404 - Not Found"
          sx={{
            width: { xs: '100%', md: '60%' },
            height: 'auto',
            mb: 4,
          }}
        />
        <Typography variant="h1" fontWeight="bold" sx={{ fontSize: { xs: '2.5rem', md: '4rem' }, color: '#333' }}>
          Oops! Page Not Found
        </Typography>
        <Typography variant="body1" sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' }, color: '#777', mt: 2 }}>
          The page you're looking for doesn't exist or has been moved.
        </Typography>
        <Button
          component={Link}
          to="/"
          variant="contained"
          sx={{
            mt: 4,
            bgcolor: '#22D23F',  
            color: 'white',
            textTransform: 'none',
            fontSize: '1.2rem',
            p: '10px 20px',
            '&:hover': {
              bgcolor: '#1bbd3e', 
            },
          }}
        >
          Go Back Home
        </Button>
      </Box>
    </Layout>
  );
};

export default PageNotFound;
