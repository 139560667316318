import React, { useEffect, useState } from 'react';
import Layout from "./../components/Layout";
import { Box, Typography, Divider, Button } from '@mui/material';
import axios from 'axios';

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [topics, setTopics] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const trendingTopics = ["Artificial Intelligence", "Electric Cars", "Indian EV Market", "EV connectors"];

    const fetchBlogs = async (page) => {
        try {
            const response = await axios.get(`https://web-backend-to6f.onrender.com/ch-adda/blogs?page=${page}`); // Adjust the URL as needed
            setBlogs(response.data.blogs);
            setTopics(response.data.topics);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching blogs:", error);
        }
    };

    useEffect(() => {
        fetchBlogs(currentPage);
    }, [currentPage]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <Layout>
            <Typography variant="h4" align="left" gutterBottom mt={2} fontWeight={600} ml ={3}>
                Blogs
            </Typography>
            <Typography variant="h5" align="left" gutterBottom mt={2} fontWeight={400} ml={3}>
            Insights from our Team
            </Typography>
            
            <Box mt={3} p={3} display="flex" justifyContent="center"  >
        <Box sx={{ width: '25%', height:'auto',pr: 4 , border: '1px solid #22D23F', // Inner box border
                    backgroundColor: '#F8F8F8', 
                    px: 2, 
                    py: 1, 
                    borderRadius: 3, 
                    mr: 2, 
                    display: 'inline-block'}}>
            <Box >
            <Typography variant="h5" align="left" gutterBottom fontWeight={550}>
                Blog Topics
            </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ mt: 1 }}>
    <ul style={{ listStyleType: 'none', padding: 0 }}>
        {topics.map((topic, index) => (
            <li key={index} style={{ marginTop: '1rem' }}>
                <input 
                    type="checkbox" 
                    style={{ marginRight: '0.5rem', accentColor: '#22D23F' }} // Use accentColor for checkbox color
                /> 
                {topic}
            </li>
        ))}
    </ul>
</Box>
        </Box>

        <Box sx={{ width: '70%' }}>
        <Typography variant="h5" align="left" gutterBottom fontWeight={550}>
                Trending Topics
            </Typography>
    <Typography variant="body1" color="green.600" sx={{ flexGrow: 1 }}>
        {trendingTopics.map((topic, index) => (
            <Box 
                key={index} 
                sx={{ 
                    border: '1px solid #22D23F', // Inner box border
                    color: 'green.600', 
                    px: 2, 
                    py: 1, 
                    borderRadius: 3, 
                    mr: 2, 
                    mb: 2, // Add bottom margin for spacing
                    display: 'inline-block' // Ensure it behaves like an inline element
                }}
            >
                {topic}
            </Box>
        ))}
    </Typography>
        {blogs.map((blog) => (
    <Box key={blog._id} className="bg-white p-4 rounded-lg shadow-md mb-4 flex" sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ flex: 1, pr: 2, mt:2 }}>
            <Typography variant="body2" color="textSecondary">{new Date(blog.date).toLocaleString('en-US', {
        month: 'short', // Short month name
        day: 'numeric', // Day of the month
        year: 'numeric' // Full year
    })}</Typography>
            <Typography variant="h5" gutterBottom>{blog.heading}</Typography>
            <Typography variant="body1" color="textSecondary">{blog.subheading}</Typography>
            <Typography variant="body2" color="textSecondary" mt={2}>
                {blog.description}
                <a href={blog.source} className="text-blue-500"> read more</a>
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <img src={blog.image} alt={blog.heading} className="rounded-lg" style={{ width: '200px', height: 'auto' }} />
        </Box>
    </Box>
))}
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                </Button>
                <Typography>{`Page ${currentPage} of ${totalPages}`}</Typography>
                <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                </Button>
            </Box>
        </Box>
    </Box>
        </Layout>
    );
};

export default Blogs;
