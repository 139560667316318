import React from "react";
import { Box, Typography, Divider,useTheme, useMediaQuery, Link } from "@mui/material";
import Logo from "../images/logo.jpeg";
import DPIIT from "../images/DPIIT.png";
import Startup from "../images/StartupIndia.png";
import "../styles/FooterStyles.css";
import { useNavigate } from 'react-router-dom';
const Footer = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


  const handleClick = () => {
    navigate("/", { replace: true }); 
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  const handleBlogClick = () => {
    navigate('/blogs');
  };

  const handleGuideClick = () => {
    navigate('/guides');
  };
  const handleHomeClick = () => {
    navigate('/');
  };
  const handleCMSClick = () => {
    navigate('/cms');
  };
  const handleChargerClick = () => {
    navigate('/chargers');
  };
  const handleCareerClick = () => {
    navigate('/career');
  };
  const handleContactClick = () => {
    navigate('/contact');
  };





  return (
    <Box sx={{ backgroundColor: "#373737", color: "white", p: { xs: 4, md: 8 } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isSmallScreen ? "center" : "flex-start",
          mb: 8,
          gap: isSmallScreen ? 4 : 0,
        }}
      >
       {/* Logo, Company Info, and DPIIT logo */}
<Box sx={{ textAlign: isSmallScreen ? "center" : "left" }}>
  {/* Main Logo and Company Info */}
  <Box sx={{ display: "flex", alignItems: "center" }}>
  <Link to="/" onClick={handleClick} style={{ textDecoration: 'none', color: 'inherit' }}> 
    <img
      src={Logo}
      alt="logo"
      height="40"
      width="70"
      style={{ marginRight: "16px" }}
    />
    </Link>
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1.25rem" }}>
        ChargingAdda
      </Typography>
      <Typography>India's leading EV charging company</Typography>
    </Box>
  </Box>

  {/* DPIIT Logo positioned below the main logo */}
  <Box sx={{ mt: 4, display: "flex", justifyContent: isSmallScreen ? "center" : "flex-start" }}>
    <img
      src={DPIIT}
      alt="DPIIT logo"
      height="90"
      width="90"
      style={{ marginRight: "26px" }}
    />
    <img
      src={Startup}
      alt="Startup logo"
      height="90"
      width="90"
    />
  </Box>
</Box>


        {/* Quick Links, Support, and Contact Info */}
        <Box sx={{ display: "flex", gap: isSmallScreen ? 2 : 4, flexDirection: isSmallScreen ? "column" : "row" }}>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Quick Links
            </Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
  <li style={{ marginBottom: "10px" }}> 
    <button
      onClick={() => {
        handleHomeClick();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: 'color 0.3s',
      }}
      onMouseOver={(e) => (e.target.style.color = 'green')} 
      onMouseOut={(e) => (e.target.style.color = 'white')}
    >
      Home
    </button>
  </li>
  <li style={{ marginBottom: "10px" }}>
    <button
      onClick={() => {
        handleCMSClick();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: 'color 0.3s',
      }}
      onMouseOver={(e) => (e.target.style.color = 'green')}
      onMouseOut={(e) => (e.target.style.color = 'white')}
    >
      CMS
    </button>
  </li>
  <li style={{ marginBottom: "10px" }}>
    <button
      onClick={() => {
        handleChargerClick();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: 'color 0.3s',
      }}
      onMouseOver={(e) => (e.target.style.color = 'green')}
      onMouseOut={(e) => (e.target.style.color = 'white')}
    >
      EV Chargers
    </button>
  </li>
  <li style={{ marginBottom: "10px" }}>
    <button
      onClick={() => {
        handleCareerClick();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: 'color 0.3s',
      }}
      onMouseOver={(e) => (e.target.style.color = 'green')}
      onMouseOut={(e) => (e.target.style.color = 'white')}
    >
      Careers
    </button>
  </li>
</ul>

          </Box>

          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Support
            </Typography>
            <ul style={{ listStyleType: "none", padding: 0 }}>
  <li style={{ marginBottom: "10px" }}> {/* Adds gap between list items */}
    <button
      onClick={() => { handleGuideClick(); window.scrollTo({ top: 0, behavior: "smooth" }); }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: "color 0.3s", // Smooth transition for hover effect
      }}
      onMouseOver={(e) => e.target.style.color = "green"}
      onMouseOut={(e) => e.target.style.color = "white"}
    >
      Guides
    </button>
  </li>
  <li style={{ marginBottom: "10px" }}>
    <button
      onClick={() => { handleBlogClick(); window.scrollTo({ top: 0, behavior: "smooth" }); }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: "color 0.3s",
      }}
      onMouseOver={(e) => e.target.style.color = "green"}
      onMouseOut={(e) => e.target.style.color = "white"}
    >
      Our Blog
    </button>
  </li>
  <li style={{ marginBottom: "10px" }}>
    <button
      onClick={() => { handleContactClick(); window.scrollTo({ top: 0, behavior: "smooth" }); }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: "color 0.3s",
      }}
      onMouseOver={(e) => e.target.style.color = "green"}
      onMouseOut={(e) => e.target.style.color = "white"}
    >
      Contact Us
    </button>
  </li>
</ul>

          </Box>

          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Contact Us
            </Typography>
            <Typography>hello@chargingAdda.com</Typography>
            <Typography>
              #23/1, 4th D Cross Road,
              <br />
              Maruthy Nagar, BTM 1st Stage, <br />
              Bangalore, Karnataka, India <br />
              560068
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Footer Bottom Section */}
      <Divider sx={{ borderColor: "gray" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          pt: 4,
          fontSize: "0.875rem",
          textAlign: isSmallScreen ? "center" : "left",
          gap: isSmallScreen ? 2 : 0,
        }}
      >
        <Box sx={{ display: "flex", gap: 4, justifyContent: isSmallScreen ? "center" : "flex-start" }}>
          <Typography>English</Typography>
          <Typography>
          <Link href = 'https://drive.google.com/file/d/1j_b1d7IZeh98ScN8B_dB56NNwNO7Os7_/preview'
          underline="none"
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: "color 0.3s",
      }}
      onMouseOver={(e) => e.target.style.color = "green"}
      onMouseOut={(e) => e.target.style.color = "white"}
    >Privacy Policy
    </Link>
    </Typography>
          <Typography>
          <button
      onClick={() => { handleContactClick(); window.scrollTo({ top: 0, behavior: "smooth" }); }}
      style={{
        background: 'none',
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        color: 'white',
        fontSize: 'inherit',
        transition: "color 0.3s",
      }}
      onMouseOver={(e) => e.target.style.color = "green"}
      onMouseOut={(e) => e.target.style.color = "white"}
    >
      Support
    </button></Typography>
        </Box>
        <Typography sx={{ textAlign: isSmallScreen ? "center" : "right" }}>
          2023. ChargingAdda. All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
