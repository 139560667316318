import React,  { useState } from 'react'
import Layout from "../components/Layout";
import { Box, Typography, Button, Collapse, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const FAQ = () => {
    const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleHelpCentre = (id) => {
    navigate(`/contact`); 
  };
  const faqs = [
    {
      question: "1. What are the different types of electric vehicles?",
      answer: "Along with pure battery electric vehicles (BEVs) there are two more types of EVs. Plug-in Hybrid electric vehicles (PHEVs) and Hybrid electric vehicles (HEVs).",
    },
    {
      question: "2. Are electric vehicles better than ICE vehicles?",
      answer: "Yes, electric vehicles are better than ICE vehicles as the former emits fewer vehicular pollutants. A CNG-powered or fuel-powered vehicle contributes to more particular missions. Higher fuel and energy consumption harms the environment. On the contrary, EVs are more efficient as more than half of the energy is used in the propulsion of vehicles, and a very less amount is used in friction force.",
    },
    {
      question: "3. How long does it take to charge an EV?",
      answer: "EV charging isn’t as linear as fuelling a fuel tank. Charging an electric vehicle depends on various factors such as vehicle type, charging equipment, power source, etc. Charging a larger battery pack may take more time than the smaller one. However, the power source is the main thing that clarifies the charging system. EV charging can be classified into three levels Level 1 Charging at home is very slow and takes hours. Level 2 Charging at public charging stations takes a few minutes to an hour to charge an EV. The charging speed depends on the power and voltage an EVSE deals in. Level 3 It refuels electric vehicles at speed as charging is done through DC fast chargers. However, the fast-charging EVSE network is very less.",
    },
    {
        question: "4. What is range anxiety? Should we be concerned about it?",
        answer: "Range anxiety is a driver’s fear regarding energy storage to cover road journeys or reaching a charging station before the car range signals refueling. It is a major psychological barrier against users’ mass adoption of electric vehicles. Buying an EV against the mass adoption of e-mobility is a big deal in developing countries like India, where the EV ecosystem is still in its initial stage. Range anxiety is obvious as India still needs to develop sufficient EV charging stations.",
      },
      {
        question: "5. Are electric vehicles safe? Do they catch fire?",
        answer: "Like ICE vehicles, things can go wrong in the case of electric vehicles due to their electric malfunction. Although electric vehicles have fewer movable parts as compared to ICE, vehicles still have lithium-ion batteries which emit a lot of heat when used. The thermal management system plays a critical role. If gone wrong, it is prone to catch fire, and this can lead to lethal accidents.",
      },
      {
        question: "6. Who can set up public charging stations in India?",
        answer: "The government of India has made charging a de-licensed activity meaning any individual/entity is free to set up public charging stations without the requirement of a license provided that, such stations meet the technical, safety as well as performance standards and protocols laid down under the guidelines as well as norms/ standards/ specifications laid down by Ministry of Power, Bureau of Energy Efficiency (BEE) and Central Electricity Authority (CEA) from time to time.",
      },
      {
        question: "7. Why ChargingAdda Express?",
        answer: "We have a transparent policy for how you can build your own Electric Vehicle Charging Station and monitor seamlessly sitting any part of the planet.",
      },
      {
        question: "8. What is the Range of the Charger?",
        answer: "We have a range of chargers like, 3.4kW,7.7kW,14.4kW,22kW,30kW, 60kW, 120kW, 180kW, and 360kW chargers.",
      },
      {
        question: "9. What types of charging connectors are used for electric vehicles?",
        answer: "The most common types of charging connectors for electric vehicles are the Type 1 (J1772) and Type 2 (Mennekes) connectors. However, there are also other connector types used globally, such as CHAdeMO and CCS (Combo).",
      },
      {
          question: "10. Can i charge an electric vehicle at home?",
          answer: "Yes, you can charge an electric vehicle at home. To do so, you will need to install a dedicated charging unit, often referred to as an Electric Vehicle Supply Equipment (EVSE), at your residence. This allows you to plug your EV into the charging unit and conveniently charge it overnight or whenever it is parked at home.",
        },
        {
          question: "11. How much does it cost to charge an electric vehicle?",
          answer: "The cost of charging an electric vehicle varies depending on factors such as electricity rates, the charging station's pricing structure, and the efficiency of your vehicle. Charging at home is typically more cost-effective than using public charging stations, but rates may vary depending on your utility provider and tariff plans.",
        },
        {
          question: "12. Can i use a charging station that is not compatible with my electric vehicle?",
          answer: "It is important to use charging stations that are compatible with your electric vehicle. Different EV models have different charging connector types and charging capabilities. Using an incompatible charging station could result in damage to your vehicle or the charging infrastructure.",
        },
        {
            question: "13. How can i maintain the battery health of my electric vehicle?",
            answer: "To maintain the battery health of your electric vehicle, it is recommended to avoid extreme temperatures, both hot and cold, whenever possible. It is also advisable to charge your vehicle regularly and avoid consistently charging to 100% or discharging to 0%. Many electric vehicles have built-in battery management systems that help optimize charging and prolong.",
        }
  ];

    return (
        <Layout>
        <Box textAlign="center" mb={2} mt ={3}>
          <Typography variant="h4" fontWeight="bold">Frequently Asked Questions</Typography>
        </Box>
        <Box maxWidth="1000px" mx="auto" bgcolor="white" p={4} borderRadius={3} boxShadow={3}>
  {faqs.map((faq, index) => (
    <Box key={index} mb={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{faq.question}</Typography>
        <IconButton onClick={() => handleToggle(index)}>
          {openIndex === index ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      <Collapse in={openIndex === index}>
        <Box mt={2}>
          {/* Check if the answer is an array or string */}
          {Array.isArray(faq.answer) ? (
            <ul>
              {faq.answer.map((point, i) => (
                  <Typography color="textSecondary">{point}</Typography>
               
              ))}
            </ul>
          ) : (
            <Typography color="textSecondary">{faq.answer}</Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  ))}
</Box>
<Box textAlign="center" mt={4} mb={3}>
          <Button variant="contained" color="primary" sx={{ borderRadius: 20 }}
          onClick={() => handleHelpCentre()}>
            Help Center
          </Button>
        </Box>
            </Layout>
    )
}
export default FAQ;