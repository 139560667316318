import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router-dom';
const CareerTable = () => {
    const { role } = useParams(); // Grabs role filter from URL params (if any)
  const [careers, setCareers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const apiUrl = role
          ? `https://web-backend-to6f.onrender.com/ch-adda/career/filter/${role}`
          : 'https://web-backend-to6f.onrender.com/ch-adda/career/filter';
        const response = await axios.get(apiUrl);
        setCareers(response.data.careers);
      } catch (error) {
        console.error("Error fetching careers:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [role]);

  if (loading) {
    return <div>Loading...</div>;
  };

  const handleApplyNow = (id) => {
    navigate(`/careers/${id}`);
  };

  

  return (
    <Layout>
    <div>
      <Typography variant="h4" align="center" gutterBottom mt={3}>
        Current Job Openings
      </Typography>
      
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>Roles</strong></TableCell>
            <TableCell><strong>Location</strong></TableCell>
            <TableCell style={{  textAlign: 'right'}}><strong>Role Type</strong></TableCell>
            <TableCell style={{ color: 'green' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {careers.map((career) => (
              <TableRow key={career._id}>
                {/* Left align the role name */}
                <TableCell style={{ textAlign: 'left', width: '30%' }}>{career.role}</TableCell>
                <TableCell>{career.location}</TableCell>
                <TableCell style={{ color: 'green' , textAlign: 'right'}}>{career.roleType}</TableCell>
                {/* Right align the button */}
                <TableCell style={{ textAlign: 'right', width: '30%' }}>
                  <Button variant="contained" color="primary" onClick={() => handleApplyNow(career._id)}>Apply Now</Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <Box mt={4} mb={3}>
        <Typography variant="h5" align="center" fontWeight="bold">
          Interested in building an Electric Vehicle ecosystem?
        </Typography>
        <Typography variant="h5" align="center" color="success" fontWeight="bold" mt={2}>
        You can also reach out to us at{' '}
  <a href="mailto:chargingadda@gmail.com" style={{ color: 'inherit', textDecoration: 'underline', fontWeight: 'bold' }}>
    chargingadda@gmail.com
  </a>
        </Typography>
      </Box>
    </div>
    </Layout>
  )
};

export default CareerTable;