import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, Button, List, ListItem, Divider } from '@mui/material';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router-dom';

const CareerDesc = () => {
    const { id } = useParams(); // Fetch the job ID from URL params
    const [jobDetails, setJobDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchJobDetails = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`https://web-backend-to6f.onrender.com/ch-adda/career/${id}`);
          setJobDetails(response.data.job);
        } catch (error) {
          console.error("Error fetching job details:", error);
        }
        setLoading(false);
      };
  
      fetchJobDetails();
    }, [id]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (!jobDetails) {
      return <div>No Job Details Found!</div>;
    }
  
    const handleBack = () => {
        navigate('/careers/filter');
      };

    return (
        <Layout>
      <Box mt={4} p={3}>
        <Typography variant="h4" align="center" gutterBottom>
          {jobDetails.role} - {jobDetails.roleType}
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary">
          Location: {jobDetails.location}
        </Typography>
        <Box align = "center">
        <Button
          variant="contained"
          color="primary"
          width = "30%"
          onClick={() => window.location.href = 'mailto:chargingadda@gmail.com'}
          sx={{ mt: 2}}
        >
          Apply Now
        </Button>
        </Box>
  
        <Divider sx={{ mt: 2, mb: 2 }} />
  
        <Typography variant="h5" gutterBottom>
          About The Job:
        </Typography>
        <List>
          {jobDetails.aboutTheJob.map((item, index) => (
            <ListItem key={index}>
              - {item}
            </ListItem>
          ))}
        </List>
  
        <Divider sx={{ mt: 2, mb: 2 }} />
  
        <Typography variant="h5" gutterBottom>
          Responsibilities:
        </Typography>
        <List>
          {jobDetails.responsibilities.map((item, index) => (
            <ListItem key={index}>
              - {item}
            </ListItem>
          ))}
        </List>
  
        <Divider sx={{ mt: 2, mb: 2 }} />
  
        <Typography variant="h5" gutterBottom>
          Minimum Qualifications:
        </Typography>
        <List>
          {jobDetails.minimumQualifications.map((item, index) => (
            <ListItem key={index}>
              - {item}
            </ListItem>
          ))}
        </List>
  
        <Divider sx={{ mt: 2, mb: 2 }} />
  
        <Typography variant="h5" gutterBottom>
          Preferred Qualifications:
        </Typography>
        <List>
          {jobDetails.preferredQualifications.map((item, index) => (
            <ListItem key={index}>
              - {item}
            </ListItem>
          ))}
        </List>
  
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Box align="center">
        <Button
          variant="contained"
          color="primary"
          width = "30%"
          onClick={() => handleBack()}
          sx={{ mt: 2}}
        >
          Back To All Jobs
        </Button>
        </Box>
      </Box>
      </Layout>
    );
  };
  
  export default CareerDesc;