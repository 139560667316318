import React,{ useState }  from 'react'
import Layout from "./../components/Layout";
import { Box, Typography, Button, TextField, Divider } from '@mui/material';
import Banner from "../images/ContactUs.png";
import axios from "axios";
const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    message:'',
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
  });

  const [backendError, setBackendError] = useState(''); // State for backend errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false }); // Reset the error for the current field
  };

  const handleSubmit = async () => {
    const newErrors = {
      firstName: formData.firstName === '',
      lastName: formData.lastName === '',
      emailId: formData.emailId === '',
    };
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);
    if (!hasErrors) {
      try {
        const response = await axios.post("https://web-backend-to6f.onrender.com/ch-adda/user", formData);
        console.log("Response:", response.data);
        setBackendError('');
      } catch (error) {
        if (error.response && error.response.data) {
          const serverError = error.response.data.message || 'An error occurred';
          setBackendError(serverError);
        } else {
          setBackendError('There was an issue submitting the form. Please try again later.');
        }
      }
    }
  };
  return (
    <Layout>
      <Box mt={4} p={3} display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <Box 
          sx={{ display: 'flex', backgroundColor: 'white', p: 3, borderRadius: 2, boxShadow: 3 }} 
          width={{ xs: '100%', sm: '80%', md: '85%' }}
        >
          <Box sx={{ width: '50%', pr: 4 }}>
            <Typography variant="h4" align="center" gutterBottom>
              We'd love to help
            </Typography>
            <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
              We are a full-service agency with experts ready to help. We'll get in touch within 24 hours.
            </Typography>
            <Divider sx={{ my: 2 }} />

            <form>
              <Box sx={{ display: 'flex', mb: 2 }}>
                <TextField 
          variant="outlined"
          label="First Name*"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          error={errors.firstName}
          helperText={errors.firstName ? 'First name is required' : '' }
            sx={{ mr: 1 }}
                />
                <TextField 
          variant="outlined"
          label="Last Name*"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          error={errors.lastName}
          helperText={errors.lastName ? 'Last name is required' : '' }
            sx={{ ml: 1 }}
                />
              </Box>
              <TextField 
          variant="outlined"
          label="Email*"
          name="emailId"
          value={formData.emailId}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          error={errors.emailId}
          helperText={errors.emailId ? 'Email is required' : '' }
          sx={{ mb: 2 }} 
              />
              <TextField 
          variant="outlined"
          label="Message"
          name="message"
          value={formData.message}
          fullWidth
          multiline
          rows={5}
          margin="normal"
          sx={{ mb: 2 }} 
              />
              <Button 
                variant="contained" 
                color="primary" 
                width="70%"
                sx={{ mt: 2 }}
                onClick={handleSubmit}
              >
                Send Message
              </Button>
              {backendError && (
        <Typography color="error" variant="body1" mt={2}>
          {backendError}
        </Typography>
      )}
            </form>
          </Box>

          <Box sx={{ width: '70%', height: '80%'}}>
            <img 
              src={Banner}
              alt="Electric car charging stations with a car parked beside them" 
              className="rounded-lg shadow-lg" 
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default Contact;