import React , {useState, useEffect} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import axios from 'axios';
import Layout from "./../components/Layout";
import Banner from "../images/Home.png";
import Iphone from "../images/iPhone.png";
import rect315 from "../images/Rectangle315.jpg";
import rect316 from "../images/Rectangle316.png";
import rect317 from "../images/Rectangle317.png";
import "../styles/HomeStyles.css";
import { Box, Container, Grid, Typography, Card, CardContent, Avatar, Divider, Button, IconButton, Collapse } from "@mui/material";
import Co2TwoToneIcon from '@mui/icons-material/Co2TwoTone';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import PersonIcon from '@mui/icons-material/Person';
import BoltIcon from '@mui/icons-material/Bolt';
import PaymentIcon from '@mui/icons-material/Payment';
import InsightsIcon from '@mui/icons-material/Insights';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleHelpCentre = (id) => {
    navigate(`/contact`); 
  };

    const stats = [
        { icon: <FlashOnIcon />, count: "532", label: "Units of energy sold" },
        { icon: <Co2TwoToneIcon />, count: "372", label: "Kg of CO2 emissions saved" },
        { icon: <DirectionsCarIcon />, count: "5911", label: "Km Powered" },
        { icon: <PersonIcon />, count: "23", label: "Active users served" },
      ];
      const features = [
        {
          icon: <BoltIcon sx={{ fontSize: 48, color: 'gray' }} />,
          title: 'Seamless charging experience',
          description:
            'Our software provides a hassle-free charging experience, allowing customers to locate and reserve stations, check availability, and receive notifications when their vehicle is fully charged.',
        },
        {
          icon: <PaymentIcon sx={{ fontSize: 48, color: 'gray' }} />,
          title: 'Payment Integration',
          description:
            'Say goodbye to manual payment processes. Our software enables seamless integration with popular payment gateways, allowing users to pay for charging services effortlessly.',
        },
        {
          icon: <InsightsIcon sx={{ fontSize: 48, color: 'gray' }} />,
          title: 'Intelligent Analytics',
          description:
            'Gain insights into charging patterns, peak usage times, and station performance. Our analytics module enables data-driven decisions to optimize your infrastructure and enhance customer satisfaction.',
        },
      ];

      const products = [
        { title: 'Product 1', imageUrl: rect315, description: '3.3Kw AC Charger' },
        { title: 'Product 2', imageUrl: rect316, description: '3.3Kw EV Charger' },
        { title: 'Product 3', imageUrl: rect317, description: '3.3Kw EV Charger Automobiles' },
      ];
    
      const faqs = [
        {
          question: "Why do I need a charger, can I not just use a 13A plug?",
          answer: [
            "A charger has multiple advantages over a 13A plug -",
            "1.Fast Charging: EV Chargers depending upon their rated capacity can charge your EV at much faster rates (multiple times) compared to a 13A plug point.",
            "2. Safety: EV Chargers come with many in-built protection systems which enhance overall safety.",
            "3. Access Control: EV Chargers come with OTP / RFID authorization systems. This ensures no one can misuse the charging point at your parking lot without your permission.",
            "4. Smart Features: Most EV Chargers come with features like remote access and control, smart charging, etc., which are not available in a 13A plug."
          ],
        },
        {
          question: "What is a standard installation?",
          answer: "Standard installation comprises of supplies and services which are generally required at the time of installation such as one-time installation activities as per ChargingAdda approved designs, certain length of cabling work, cable casing etc. It does not include any special requirement which may be required at site. Please read standard installation scope and Terms and Conditions in the offer for more details before confirming your order.",
        },
        {
          question: "What is the difference between the charger given to me by the car company and an EZcharge home charger?",
          answer: "ChargingAdda home chargers comes with multiple features which might not be there in basic charger provided by your car company. These features may include - Faster Charging rate, Safety & Protection, Access control, Smart Charging features etc.",
        },
      ];


      const [testimonials, setTestimonials] = useState([]);

      useEffect(() => {
        // Replace this with your actual API endpoint
        const apiEndpoint = 'https://web-backend-to6f.onrender.com/ch-adda/testimonial';
        
        axios.get(apiEndpoint)
          .then(response => {
            setTestimonials(response.data.testimonials);
            console.log(response.data.testimonials);
          })
          .catch(error => {
            console.error('Error fetching testimonials:', error);
          });
      }, []);

      const slides = [];
  for (let i = 0; i < testimonials.length; i += 2) {
    slides.push(testimonials.slice(i, i + 2));
  }
    
  return (
    <Layout>
      <div className="home" style={{
    backgroundImage: `url(${Banner})`,               
  }}>
         </div>
         <Box
  display="flex"
  flexDirection="column" // Ensures the content is arranged in a column
  justifyContent="flex-start"
  alignItems="center"
  height="50vh"
  bgcolor="white"
  pt={2}
>
  {/* Top Title */}
  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
    Our Performance
  </Typography>

  {/* Performance Stats */}
  <Box display="flex" justifyContent="space-around" width="100%" flexGrow={1} alignItems="center" px={4} >
    {stats.map((stat, index) => (
      <Box key={index} textAlign="center" flex="1">
        <Avatar
          sx={{
            bgcolor: "#22D23F33",
            height: "60px",
            widht: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            color:"#22D03E",
          }}
        >
          {stat.icon}
        </Avatar>
        <Typography variant="h5" fontWeight="bold" mt={1} color="black">
          {stat.count}
        </Typography>
        <Typography>{stat.label}</Typography>
      </Box>
    ))}
  </Box>
</Box>

<Box bgcolor="#22D23F33"  py={6} sx={{ borderRadius: 3, boxShadow: 4 }} ml={2} mr={2}>
      <Container >
      <Box 
  display="flex" 
  justifyContent="center"  // Center horizontally
>
      <Box 
      align="center"  
          display="inline-block"  
          px={2}                  
          py={0.5}                
          mb={4}                  
          mx="auto"              
          borderColor="black"          
          borderRadius="14px"    
          textAlign="center"
          border={1}
        >
          <Typography variant="body1" fontWeight="normal" color= "black">
            Features
          </Typography>
        </Box>
        </Box>
        <Typography variant="h4" component="h2" align="center" fontWeight="bold" mb={4}>
          Why use ChargingAdda
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <Avatar
                    sx={{
                      bgcolor: 'transparent',
                      width: 64,
                      height: 64,
                      margin: '0 auto',
                      mb: 2,
                    }}
                  >
                    {feature.icon}
                  </Avatar>
                  <Typography variant="h6" fontWeight="bold" mb={2}>
                    {feature.title}
                  </Typography>
                  <Typography color="textSecondary">{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>

    <Box
      sx={{
        mt:1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        px: 4,
        py: 8,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          textAlign: { xs: 'center', md: 'left' },
          mx: 'auto',
        }}
      >
        {/* Text Content */}
        <Box
          sx={{
            flex: 1,
            mb: { xs: 8, md: 0 },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: 'black',
              fontFamily: 'Poppins, sans-serif',
              mb: 4,
            }}
          >
            Enhance and streamline your charging network performance with our CMS.
          </Typography>
          <Typography
            variant="body1"
            sx={{
                fontWeight: 400,
              color: '#000000',
              mb: 6,
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            Effortlessly manage and scale your EV charging operations with our adaptable CMS. Access tailored data insights for seamless integration and optimal performance in any setting.
          </Typography>
        </Box>

        {/* Image */}
        <Box
          sx={{
            flex: 1,
            mt: { xs: 8, md: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={Iphone}
            alt="Iphone"
            style={{
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
    </Box>

    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          mt: -7, // Margin top and bottom
        }}
      >
    <Divider sx={{
          borderColor: 'gray',
          borderWidth: '1px',
          width: '94%',
        }}/>
        </Box>

        <Box >
        <Container>
          <Box display="flex" justifyContent="center">
            <Box
              align="center"
              display="inline-block"
              px={2}
              py={0.5}
              mb={3}
              mt = {3}
              mx="auto"
              borderColor="black"
              borderRadius="14px"
              textAlign="center"
              border={1}
            >
              <Typography variant="body1" fontWeight="bold" color="black">
                Featured Products
              </Typography>
            </Box>
          </Box>
          <Grid container spacing={4}>
            {products.map((product, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Avatar
                      sx={{
                        bgcolor: 'transparent',
                        width: 300,
                        height: 400,
                        margin: '0 auto',
                        mb: 2,
                        img: {
                          objectFit: 'cover',
                          borderRadius: '8px',
                        },
                      }}
                      src={product.imageUrl}
                      alt={product.title}
                      variant="square"
                    />
                    <Typography variant="h6" fontWeight="bold" mb={2}>
                      {product.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box bgcolor="white" py={6} sx={{ borderRadius: 3, boxShadow: 4 }} mx={2} mt={4}>
  <Container>
    <Box textAlign="center" mb={2}>
      <Typography variant="caption" sx={{ bgcolor: '#E0F2FE', color: '#0284C7', px: 2, py: 0.5, borderRadius: 2 }}>
        TESTIMONIALS
      </Typography>
      <Typography variant="h4" fontWeight="bold" mt={2}>
        Client’s Testimonials
      </Typography>
    </Box>

    <Swiper
      modules={[Pagination]}
      spaceBetween={20}
      slidesPerView={1} // Default for smaller screens
      pagination={{ clickable: true }}    
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <Box 
            display="flex" 
            flexDirection={{ xs: 'column', sm: 'row' }}  // Stack vertically on small screens, horizontally on larger screens
            justifyContent="space-between" 
            gap={2}
          >
            {slide.map((testimonial) => (
              <Box 
                key={testimonial._id} 
                bgcolor="white" 
                p={4} 
                borderRadius={3} 
                boxShadow={3} 
                flex="1"
                sx={{ mb: { xs: 2, sm: 0 } }} // Add margin for small screens
              >
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar src={testimonial.profilePic} alt={`Portrait of ${testimonial.name}`} sx={{ width: 56, height: 56, mr: 2 }} />
                  <Box>
                    <Typography variant="h6">{testimonial.name}</Typography>
                    <Typography variant="body2" color="textSecondary">{testimonial.profession}</Typography>
                  </Box>
                </Box>
                <Typography color="textSecondary">
                  {testimonial.message}
                </Typography>
              </Box>
            ))}
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  </Container>
</Box>


      <Box bgcolor="#92DAFF1A" py={6} sx={{ borderRadius: 3, boxShadow: 4 }} mx={2} mt={4}>
      <Container>
        <Box textAlign="center" mb={2}>
          <Typography variant="h4" fontWeight="bold">Frequently Asked Questions</Typography>
        </Box>
        <Box maxWidth="600px" mx="auto" bgcolor="white" p={4} borderRadius={3} boxShadow={3}>
  {faqs.map((faq, index) => (
    <Box key={index} mb={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{faq.question}</Typography>
        <IconButton onClick={() => handleToggle(index)}>
          {openIndex === index ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>
      <Collapse in={openIndex === index}>
        <Box mt={2}>
          {/* Check if the answer is an array or string */}
          {Array.isArray(faq.answer) ? (
            <ul>
              {faq.answer.map((point, i) => (
                  <Typography color="textSecondary">{point}</Typography>
               
              ))}
            </ul>
          ) : (
            <Typography color="textSecondary">{faq.answer}</Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  ))}
</Box>

        <Box textAlign="center" mt={4}>
          <Button variant="contained" color="primary" sx={{ borderRadius: 20 }}
          onClick={() => handleHelpCentre()}>
            Help Center
          </Button>
        </Box>
      </Container>
    </Box>

    </Layout>
  );
};

export default Home;
