import React,  { useState, useEffect } from 'react'
import Layout from "../components/Layout";
import Banner from "../images/Charger.png";
import { Box, Typography, Button , Avatar } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const Chargers = () => {
  const [chargers, setChargers] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // State for handling errors

  // Fetch charger details from the backend
  useEffect(() => {
    const fetchChargerDetails = async () => {
      try {
        const response = await axios.get('https://web-backend-to6f.onrender.com/ch-adda/chargers');
        console.log(response);
        if (Array.isArray(response.data.chargers)) {
          setChargers(response.data.chargers);
        } else {
          throw new Error("Data format is not valid");
        }
      } catch (error) {
        console.error('Error fetching charger details:', error);
        setError('Failed to load charger details. Please try again later.');
      }
    };

    fetchChargerDetails();
  }, []);

  const handleViewMore = (id) => {
    navigate(`/charger/${id}`); 
  };

  return (
    <Layout>
      <div className="home" style={{ backgroundImage: `url(${Banner})` }}>
      </div>

      <Box py={4} px={3} bgcolor="#f4f4f4">
        <Typography variant="h4" fontWeight="600" mb={4}>
          EV Chargers
        </Typography>

        {/* Error Message */}
        {error && (
          <Typography variant="body1" color="error" mb={2}>
            {error}
          </Typography>
        )}
       <Box 
  display="grid" 
  gridTemplateColumns={{
    xs: '1fr',  
    sm: '1fr',  
    md: 'repeat(auto-fill, minmax(400px, 1fr))',
  }} 
  gap={4}
>
  {chargers.length > 0 ? (
    chargers.map((charger) => (
      <Box 
        key={charger.id} 
        p={2} 
        borderRadius={2} 
        boxShadow={3} 
        bgcolor="#ffffff"
      >
        <Avatar
          src={charger.profilePic}
          alt={charger.name}
          variant="rounded"
          sx={{ width: '100%', height: "auto", mb: 2 }}
        />
        <Typography variant="h6" fontWeight="600" mb={1}>
          {charger.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" mb={2}>
          {charger.type}
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          fullWidth 
          onClick={() => handleViewMore(charger._id)}
        >
          View More
        </Button>
      </Box>
    ))
  ) : (
    <Typography variant="body1" color="textSecondary">
      No chargers available.
    </Typography>
  )}
</Box>

      </Box>
    </Layout>
  )
}

export default Chargers;