import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, Button, Avatar, Grid } from '@mui/material';
import Layout from '../components/Layout';
import PowerIcon from '@mui/icons-material/Power';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import BoltIcon from '@mui/icons-material/Bolt';
import ShieldIcon from '@mui/icons-material/Shield';
import { useNavigate } from 'react-router-dom';

const ChargerDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the Mongo ID from the URL
  const [charger, setCharger] = useState(null);

  const handleBuyNow = (id) => {
    navigate(`/cms`); 
  };
  useEffect(() => {
    const fetchChargerDetails = async () => {
      try {
        const response = await axios.get(`https://web-backend-to6f.onrender.com/ch-adda/charger/${id}`);
        setCharger(response.data.charger);
      } catch (error) {
        console.error('Error fetching charger details:', error);
      }
    };

    fetchChargerDetails();
  }, [id]);

  if (!charger) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Layout>
    <Box py={4} px={3}>
      {/* Breadcrumb */}
      <Typography variant="body2" color="textSecondary" mb={2}>
        EV Chargers &gt; {charger.name}
      </Typography>

      {/* Main Charger Section */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {/* Charger Image */}
          <Avatar
            src={charger.profilePic}
            alt={charger.name}
            variant="rounded"
            sx={{ width: '100%', height: 'auto' }}
          />
          
          {/* Supporting Images */}
          <Box display="flex" justifyContent="space-between" mt={2}>
            {charger.supportingPics.map((pic, index) => (
              <Avatar
                key={index}
                src={pic}
                alt={`Supporting ${index + 1}`}
                variant="rounded"
                sx={{ width: '30%', height: 'auto' }}
              />
            ))}
          </Box>
        </Grid>

        {/* Charger Details */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" fontWeight="600">
            {charger.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" mb={2}>
            {charger.type}
          </Typography>
          <Typography variant="h5" color="primary" mb={2}>
            Rs. {charger.price}
          </Typography>

          {/* Add to Cart and Buy Now Buttons */}
          <Box display="flex" gap={2} mb={4}>
            <Button variant="outlined" color="primary">
              Add to cart
            </Button>
            <Button variant="contained" color="primary"
            onClick={() => handleBuyNow()}>
              Buy Now
            </Button>
            <Typography variant="body2" color="red" mb={2}>
            Please fill enquiry. 
          </Typography>
          </Box>

          {/* Product Description */}
          <Typography variant="h6" fontWeight="600" mb={2}>
            Product Description
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={4}>
            {charger.description}
          </Typography>

          <Typography variant="h6" fontWeight="600" mb={2}>
  Key Features
</Typography>

<Box>
  {/* Power Output */}
  <Box display="flex" alignItems="center" mb={1}>
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        border: '1px solid #14A72C', 
        borderRadius: '50%', 
        padding: '8px', 
        mr: 2 
      }}
    >
      <PowerIcon sx={{ color: "#14A72C" }} /> {/* Power Icon */}
    </Box>
    <Box>
      <Typography variant="body3" fontWeight="bold">
        Power Output
      </Typography>
      <Typography variant="body2" mb={2}>
        {charger.powerOutput}
        </Typography>
        </Box>
        </Box>

        <Box>
  
  <Box display="flex" alignItems="center" mb={1}>
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        border: '1px solid #14A72C', 
        borderRadius: '50%', 
        padding: '8px', 
        mr: 2 
      }}
    >
      <ElectricCarIcon sx={{ color: "#14A72C" }} /> {/* Power Icon */}
    </Box>
    <Box>
      <Typography variant="body3" fontWeight="bold">
      Compatibility
      </Typography>
      <Typography variant="body2" mb={2}>
      {charger.compatibility}
        </Typography>
        </Box>
        </Box>
        </Box>
  
        <Box display="flex" alignItems="center" mb={1}>
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        border: '1px solid #14A72C', 
        borderRadius: '50%', 
        padding: '8px', 
        mr: 2 
      }}
    >
      <BoltIcon sx={{ color: "#14A72C" }} /> {/* Power Icon */}
    </Box>
    <Box>
      <Typography variant="body3" fontWeight="bold">
      Smart Charging
      </Typography>
      <Typography variant="body2" mb={2}>
      {charger.smartCharging}
        </Typography>
        </Box>
        </Box>
        
        <Box display="flex" alignItems="center" mb={1}>
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        border: '1px solid #14A72C', 
        borderRadius: '50%', 
        padding: '8px', 
        mr: 2 
      }}
    >
      <ShieldIcon sx={{ color: "#14A72C" }} /> {/* Power Icon */}
    </Box>
    <Box>
      <Typography variant="body3" fontWeight="bold">
      Safety Features
      </Typography>
      <Typography variant="body2" mb={2}>
      {charger.safetyFeatures}
        </Typography>
        </Box>
        </Box>
 
  </Box>
        </Grid>
      </Grid>

      {/* Dimensions and Compatibility Section */}
      <Box mt={4}>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" fontWeight="bold" mb={3}>
              <strong>Dimensions:</strong> {charger.dimensions}
            </Typography>
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary" fontWeight="bold">
              <strong>Compatibility:</strong> {charger.compatibility}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
    </Layout>
  );
};

export default ChargerDetail;
