import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import Banner from "../images/bro.png";
import Layout from "./../components/Layout";

const Guides = () => {
    
    return (
        <Layout>
       <Box 
    sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' }, // Stack items on small screens
        alignItems: 'flex-start', 
        justifyContent: 'center', 
        minHeight: '65vh', 
        backgroundColor: 'white',
        p: 2, // Adjusted padding for smaller screens
    }}
>
    <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', md: 'row' }, // Stack items on small screens
        gap: 2 // Adjusted gap for smaller screens
    }}>
        <Box sx={{ flex: 1 }}> {/* Allow the box to take available space */}
            <Typography variant="h3" gutterBottom textAlign="left">
                Guides & Instructions
            </Typography>
            <ul style={{ padding: 0, listStyleType: 'none' }}>
                {[
                    { name: "MoHUA guidelines on EV charging", url: "https://archive.pib.gov.in/documents/rlink/2019/feb/p201921501.pdf" },
                    { name: "MoP guidelines on EV charging Infrastructure", url: "https://mohua.gov.in/upload/whatsnew/5c6e472b20d0aGuidelines%20(EVCI).pdf" },
                    { name: "Bharat EV Charger Specifications", url: "https://www.pluginindia.com/blogs/bharat-ev-specifications-for-ac-and-dc-charging-everything-you-need-to-know" }, 
                    { name: "EVs and Charging Infrastructure in India", url: "https://beeindia.gov.in/sites/default/files/press_releases/2019%20-%20EY%20to%20BEE%20-%20Technical%20study%20on%20EVs%20%26%20Charging%20Infrastructure.pdf" }, 
                    { name: "More on electric vehicles", url: "https://www.energy.gov/vehicles" } 
                ].map((item, index) => (
                    <li 
                        key={index} 
                        style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', color: '#1E90FF' }}
                    >
                        <Link 
                            href={item.url} 
                            underline="hover" 
                            sx={{ cursor: 'pointer', flexGrow: 1, color: '#1E90FF', fontWeight: "500" }}
                            target="_blank"  // Opens in a new tab
                            rel="noopener noreferrer" // Ensures safe opening in new tab
                        >
                            {item.name}
                        </Link>
                        <IconButton 
                            color="primary" 
                            component="a" 
                            href={item.url} 
                            download 
                            sx={{ ml: 2 }}
                        >
                            <GetAppIcon />
                        </IconButton>
                    </li>
                ))}
            </ul>
        </Box>

        <Box sx={{ position: 'relative', mb: { xs: 3, md: 0 }, flex: 1 }}> {/* Adjust margin for small screens */}
            <img 
                src={Banner}
                alt="Illustration of a person holding a sign that says 'HELPFUL TIPS!'" 
                style={{ width: '70%', height: 'auto' }} // Use 100% width for responsiveness
            />
        </Box>
    </Box>
</Box>
           
        </Layout>
    );
};

export default Guides;
