import React, { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
  Menu, MenuItem, Button
} from "@mui/material";
import Logo from "../images/logo.jpeg";
import "../styles/HeaderStyles.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from "@mui/icons-material/Menu";

import { NavLink, Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [anchorElProducts, setAnchorElProducts] = useState(null);
  const [anchorElResources, setAnchorElResources] = useState(null);
  const openProducts = Boolean(anchorElProducts);
  const openResources = Boolean(anchorElResources);

  const navigate = useNavigate(); 

  const handleClick = () => {
    navigate("/", { replace: true }); 
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  const handleClickProducts = (event) => {
    setAnchorElProducts(event.currentTarget);
  };

  const handleClickResources = (event) => {
    setAnchorElResources(event.currentTarget);
  };

  const handleCloseProducts = () => {
    setAnchorElProducts(null);
  };

  const handleCloseResources = () => {
    setAnchorElResources(null);
  };

  const handleResourceMenuClose = () => {
    handleCloseResources(); // Only close after resource selection
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        color={"black"}
        variant="h6"
        component="div"
        fontWeight={800}
        sx={{ flexGrow: 1, my: 2 }}
      >
        <Link to="/" onClick={handleClick} style={{ textDecoration: 'none', color: 'inherit' }}> 
        <img src={Logo} alt="logo" height={"70"} width="200" />
      </Link>
      ChargingAdda
      </Typography>
      <Divider />
      <ul className="mobile-navigation">
        <li>
          <NavLink to={"/"} style={{ textDecoration: 'none', fontWeight: 500 }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to={"/about"} style={{ textDecoration: 'none', fontWeight: 500 }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            About
          </NavLink>
        </li>
        <li>
        <Button
  aria-controls={openProducts ? 'products-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={openProducts ? 'true' : undefined}
  onClick={handleClickProducts}
  endIcon={<ArrowDropDownIcon />}
  sx={{ color: 'black', textTransform: 'none', fontWeight: 520, textDecoration:'none', fontSize: '1.4rem', marginTop: '-10px' }}
>
  Products
</Button>
<Menu
  id="products-menu"
  anchorEl={anchorElProducts}
  open={openProducts}
  // Removed the onClose handler to keep the menu open
  PaperProps={{
    style: {
      borderRadius: '8px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      padding: '0',
    },
  }}
>
  <MenuItem onClick={() => setAnchorElProducts(null)}> {/* Close the menu only when an item is clicked */}
    <NavLink to={"/cms"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <Typography sx={{ padding: '8px 16px' }}>CMS</Typography>
    </NavLink>
  </MenuItem>
  <MenuItem onClick={() => setAnchorElProducts(null)}>
    <NavLink to={"/chargers"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
      <Typography sx={{ padding: '8px 16px' }}>EV Chargers</Typography>
    </NavLink>
  </MenuItem>
</Menu>

        </li>
        <li>
          <Button
            aria-controls={openResources ? 'resources-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openResources ? 'true' : undefined}
            onClick={handleClickResources}
            endIcon={<ArrowDropDownIcon />}
            sx={{ color: 'black', textTransform: 'none', fontWeight: 520, textDecoration:'none', fontSize: '1.4rem',  marginTop: '-15px'}}
          >
            Resources
          </Button>
          <Menu
            id="resources-menu"
            anchorEl={anchorElResources}
            open={openResources}
            onClose={handleCloseResources}
            PaperProps={{
              style: {
                borderRadius: '8px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                padding: '0',
              },
            }}
          >
            <MenuItem onClick={handleResourceMenuClose}>
              <NavLink to={"/guides"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <Typography sx={{ padding: '8px 16px' }}>Guide and Instructions</Typography>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleResourceMenuClose}>
              <NavLink to={"/blogs"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <Typography sx={{ padding: '8px 16px' }}>Blogs</Typography>
              </NavLink>
            </MenuItem>
            <MenuItem onClick={handleResourceMenuClose}>
              <NavLink to={"/career"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <Typography sx={{ padding: '8px 16px' }}>Career</Typography>
              </NavLink>
            </MenuItem>
          </Menu>
        </li>
        <li>
          <NavLink to={"/faqs"} style={{ textDecoration: 'none', fontWeight: 500 }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            FAQs
          </NavLink>
        </li>
        <li>
          <NavLink to={"/contact"} style={{ textDecoration: 'none', fontWeight: 500 }}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            Contact us
          </NavLink>
        </li>
        <li>
        <Button
      variant="contained"
      sx={{ backgroundColor: '#90EE90', color: 'black', textTransform: 'none', fontSize: 'inherit' }} 
      onClick={() => window.location.href = 'https://app.cms.chargingadda.com/login'} 
    >
      Download
      </Button>
        </li>
      </ul>
    </Box>
  );

  return (
    <>
      <Box>
        <AppBar component={"nav"} sx={{ bgcolor: "white" }}>
          <Toolbar>
            <IconButton
              color="Black"
              aria-label="open drawer"
              edge="start"
              sx={{
                mr: 2,
                display: { sm: "none" },
              }}
              onClick={handleDrawerToggle}
            >
             <MenuIcon />
            </IconButton>
            <Link to="/" onClick={handleClick} style={{ textDecoration: 'none', color: 'inherit' }}> 
        <img src={Logo} alt="logo" height={"40"} width="70" />
      </Link>
            <Typography
              color={"Black"}
              variant="h7"
              component="div"
              sx={{  ml: 0, flexGrow: 1  }}
            >
              <span style={{ fontWeight: 500}}>ChargingAdda</span>
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <ul className="navigation-menu">
      <li>
        <NavLink activeClassName="active" to={"/"} style={{ textDecoration: 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to={"/about"} style={{ textDecoration: 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          About
        </NavLink>
      </li>
      <li>
        <Button
          aria-controls={openProducts ? 'products-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openProducts ? 'true' : undefined}
          onClick={handleClickProducts}
          endIcon={<ArrowDropDownIcon />}
          sx={{ color: 'black', textTransform: 'none', fontSize: 'inherit'}}
        >
          Products
        </Button>
        <Menu
          id="products-menu"
          anchorEl={anchorElProducts}
          open={openProducts}
          onClose={handleCloseProducts}
          PaperProps={{
            style: {
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '0',
            },
          }}
        >
          <MenuItem onClick={handleCloseProducts}>
            <NavLink to={"/cms"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
           onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <Typography sx={{ padding: '8px 16px' }}>CMS</Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleCloseProducts}>
            <NavLink to={"/chargers"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <Typography sx={{ padding: '8px 16px' }}>EV Chargers</Typography>
            </NavLink>
          </MenuItem>
        </Menu>
      </li>
      <li>
        <Button
          aria-controls={openResources ? 'resources-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openResources ? 'true' : undefined}
          onClick={handleClickResources}
          endIcon={<ArrowDropDownIcon />}
          sx={{ color: 'black', textTransform: 'none' , fontSize: 'inherit'}}
        >
          Resources
        </Button>
        <Menu
          id="resources-menu"
          anchorEl={anchorElResources}
          open={openResources}
          onClose={handleCloseResources}
          PaperProps={{
            style: {
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '0',
            },
          }}
        >
          <MenuItem onClick={handleCloseResources}>
            <NavLink to={"/guides"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <Typography sx={{ padding: '8px 16px' }}>Guide and Instructions</Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleCloseResources}>
            <NavLink to={"/blogs"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <Typography sx={{ padding: '8px 16px' }}>Blogs</Typography>
            </NavLink>
          </MenuItem>
          <MenuItem onClick={handleCloseResources}>
            <NavLink to={"/career"} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <Typography sx={{ padding: '8px 16px' }}>Career</Typography>
            </NavLink>
          </MenuItem>
        </Menu>
      </li>
      <li>
        <NavLink to={"/faqs"} style={{ textDecoration: 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          FAQs
        </NavLink>
      </li>
      <li>
        <NavLink to={"/contact"} style={{ textDecoration: 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Contact us
        </NavLink>
      </li>
      <li>
      <Button
      variant="contained"
      sx={{ backgroundColor: '#90EE90', color: 'black', textTransform: 'none', fontSize: 'inherit', marginRight:-3 }} 
      onClick={() => window.location.href = 'https://app.cms.chargingadda.com/login'} 
    >
      Download
    </Button>
      </li>
    </ul>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "240px",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box>
          <Toolbar />
        </Box>
      </Box>
    </>
  );
};

export default Header;
