import React from 'react'
import Layout from "../components/Layout";
import Banner from "../images/Career.png";
import { Box, Typography, Button , Grid } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarIcon from '@mui/icons-material/Star';
import FlagIcon from '@mui/icons-material/Flag';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useNavigate } from 'react-router-dom';

const Career = () => {
  const navigate = useNavigate();

  const handleViewAll = () => {
    navigate('/careers/filter');
  };

  const handleFilter = (role) => {
    navigate(`/careers/filter/${role}`);
  };
  return (
    <Layout>
      <div className="home" style={{ backgroundImage: `url(${Banner})` }}>
      </div>
      <Box py={4} px={3}>
      {/* Title Section */}
      <Typography variant="h3" fontWeight="bold" mb={4} align="center">
        Why join ChargingAdda?
      </Typography>

      {/* Features Section */}
      <Grid container spacing={4} justifyContent="space-around" mb={4}>
        {[
          { icon: <TrendingUpIcon sx={{ fontSize: 50, color: 'green' }} />, title: "Growth Opportunities", description: "Experience dynamic growth and career advancement in our fast-paced environment." },
          { icon: <StarIcon sx={{ fontSize: 50, color: 'green' }} />, title: "Impactful Work", description: "Collaborate with us to promote EV adoption and reduce carbon emissions." },
          { icon: <FlagIcon sx={{ fontSize: 50, color: 'green' }} />, title: "Learning and Challenges", description: "Thrive in a dynamic, innovative environment with continuous learning and exciting challenges." },
          { icon: <EmojiEventsIcon sx={{ fontSize: 50, color: 'green' }} />, title: "Innovation and Exploration", description: "Enjoy the freedom to innovate and explore new ideas in our supportive team environment." }
        ].map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Box className="bg-green-100" borderRadius="50%" p={2} mb={2}>
                {item.icon}
              </Box>
              <Typography variant="h6" fontWeight="bold">
                {item.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {item.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Where do you belong? Section */}
      <Typography variant="h3" fontWeight="bold" mb={4} align="center">
        Where do you belong?
      </Typography>

      <Box display="flex" justifyContent="center" gap={2} flexWrap="wrap" mb={4}>
  {[ "Software Development", 'Product Development', "Sales", "Operations", "Buisness", "Marketing"].map((role, index) => (
    <Button
      key={index}
      variant="outlined"
      color="success"
      sx={{
        borderRadius: '50px',
        px: 4,
        py: 2,
        textTransform: 'none', // This ensures the text is not transformed to uppercase
        color: 'black', // Ensures the text color is black
        fontWeight: 'bold'
      }}
      onClick={() => handleFilter(role)}
    >
      {role}
    </Button>
  ))}
</Box>

      {/* View All Openings Button */}
      <Box display="flex" justifyContent="center">
        <Button variant="contained" color="primary" sx={{ borderRadius: '50px', px: 6, py: 2 }} onClick={() => handleViewAll()}>
          View all openings
        </Button>
      </Box>
    </Box>
    </Layout>
  )
}

export default Career;