import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import CMS from "./pages/CMS";
import Pagenotfound from "./pages/Pagenotfound";
import ChargerDetail from "./pages/ChargerDetail";
import CareersPage from "./pages/CareerTable";
import ChargerDesc from "./pages/CareerDesc";
import Career from "./pages/Career";
import Blogs from "./pages/Blogs";
import Guides from "./pages/Guides";
import Chargers from "./pages/Chargers";
import FAQ from "./pages/FAQ";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/cms" element={<CMS />} />
          <Route path="/chargers" element={<Chargers />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/charger/:id" element={<ChargerDetail />} />
          <Route path="/careers/filter/:role?" element={<CareersPage />} />
          <Route path="/careers/:id" element={<ChargerDesc />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/guides" element={<Guides />} />
          <Route path="/faqs" element={<FAQ />} />
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
